<template>
  <div >
    <input type="file" @change="onFileChange" ref="fileInput" />

    <button @click="uploadFile" class="btn btn-success btn-sm">
      <i class="fas fa-upload"></i> Upload
    </button>


    <div v-if="uploadProgress > 0">
      <progress :value="uploadProgress" max="100"></progress>
      <span>{{ uploadProgress }}%</span>
    </div>
    <div v-if="progress > 0" class="progress mt-2">
      <div class="progress-bar" role="progressbar" :style="{ width: progress + '%' }"
        :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">{{ progress }}%
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      selectedFile: null,
      base_url: process.env.VUE_APP_SERVICE_URL,
      selectedFolder: this.$route.params.folderId,
      uploadProgress: 0,
    };
  },
  props: {
    progress: {
      type: Number,
      default: 0
    }
  },
  methods: {
    onFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    fetchFiles() {
      axios
        .get(`${this.base_url}/api/folders/${this.selectedFolder}/files`, {
          headers: {
            Authorization: `Bearer ` + localStorage.getItem("lfms_user"),
          },
        })
        .then((response) => {
          this.$emit('filesFetched', response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    uploadFile() {
      if (!this.selectedFile) {
        this.$notify({
          type: "error",
          title: "No file selected",
          text: "Please select a file to upload.",
        });
        return;
      }

      const formData = new FormData();
      formData.append("file", this.selectedFile);

      if (this.selectedFolder) {
        formData.append("folder_id", this.selectedFolder);
      }

      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("lfms_user"),
      };

      axios
        .post(`${this.base_url}/api/files`, formData, {
          headers,
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then((response) => {
          this.$notify({
            type: "success",
            title: "Success",
            text: "File uploaded successfully!",
          });
          this.uploadProgress = 0;
          this.$refs.fileInput.value = null; // Reset file input
          this.fetchFiles(); // Fetch files after successful upload
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            title: "Something went wrong!",
            text: error.response.data.message,
          });
          console.log(error);
          this.uploadProgress = 0;
        });
    },
  },
};
</script>

<style scoped>

progress {
  width: 100%;
  margin-top: 10px;
}

.progress {
  height: 20px;
}

.progress-bar {
  background-color: #4caf50;
  text-align: center;
  color: white;
}
</style>
