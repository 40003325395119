import { VueReCaptcha } from 'vue-recaptcha-v3';

const RECAPTCHA_SITE_KEY = '6LeXkSAqAAAAANgIMg0NWbFC5qQQzhuGa4Zw7hUs';
const RECAPTCHA_ROUTE_NAMES = ['SignUp'];

export function setupReCaptcha(app, router) {
  app.use(VueReCaptcha, { siteKey: RECAPTCHA_SITE_KEY, autoHideBadge: true });
  const recaptchaLoaded = app.config.globalProperties.$recaptchaLoaded;

  recaptchaLoaded().then(() => {
    const recaptchaInstance = app.config.globalProperties.$recaptchaInstance;
    function toggleReCaptcha(routeName) {
      if (recaptchaInstance.value) {
        RECAPTCHA_ROUTE_NAMES.includes(routeName)
          ? recaptchaInstance.value.showBadge()
          : recaptchaInstance.value.hideBadge();
      }
    }
    // Initial check for the current route
    toggleReCaptcha(router.currentRoute.value.name || '');
    // Watch for route changes
    router.beforeEach((to, _from, next) => {
      toggleReCaptcha(to.name || '');
      next();
    });
  });
}
