<template>
  <div class="lawyer-list">
    <p><strong>Lawyer(s)</strong></p>
    
      <p v-for="lawyer in lawyers" :key="lawyer.id">
        <span v-if="lawyer.user && lawyer.user.name"
        style="cursor: pointer"
        v-tooltip.top="'Show Lawyer Details'"
        @click="showProfilePopUp(lawyer)">{{ lawyer.user ? lawyer.user.name : "" }}</span>
      </p>
    
  </div>

  <user-profile
    @afterHideProfileCard="isShowUserProfile = false"
    v-if="isShowUserProfile"
    :lawyer="lawyer"
    :display="true"
    :lawyerTotalPetitions="lawyerTotalPetitions"
  />
</template>

<script>
import UserProfile from "../users/UserProfile.vue";
import axios from "axios";

export default {
  props: ["lawyers"],
  components: {
    UserProfile,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_SERVICE_URL,
      isShowUserProfile: false,
      lawyer: {},
      lawyerTotalPetitions: "",
    };
  },
  methods: {
    showProfilePopUp(lawyer) {
      this.isShowUserProfile = true;
      this.lawyer = lawyer;
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("lfms_user"),
      };
      axios
        .get(this.base_url + "/api/get_lawyer_total_petitions", {
          headers,
          params: { lawyer_id: lawyer.user.id },
        })
        .then((response) => {
          this.lawyerTotalPetitions = response.data.lawyer_total_petitions;
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "error",
            title: "Something went wrong!",
            text: error.response.data.message,
          });
        });
    },
  },
};
</script>

<style scoped>
.lawyer-list {
  font-size: 12px;
  line-height: 0.5px;
}
</style>
