<template>
  <ConfirmPopup />
  <BlockUI :blocked="!isLoaded" :fullScreen="true" :autoZIndex="true" :baseZIndex="99999">
    <main id="main">
      <page-header title="File Manager" :showUsers="true" :hideCaseFiles="true" :route_object="route_obj"
        :header_button=false :header_button_text="header_button_text" />

      <!-- Add Back to File Manager Link -->


      <!-- ======= Services Section ======= -->
      <section id="services" class="services section-bg">
        <BlockUI :blocked="users" :fullScreen="true">

          <div class="container" data-aos="fade-up">


            <div class="row">
              <div class="col-3 mb-2">
                <CustomTree :value="foldersTree" class="w-full md:w-[30rem]"></CustomTree>
              </div>

              <div class="col-9 mb-2">

                <div class="row">
                  <div class="col-md-12">

                    <p>
                      <router-link v-if="selectedFolder" to="/file-manager">
                        <span class="fa fa-home"></span> Home > </router-link>
                      <span class="ml-3" v-for="(breadcrumb, index) in breadcrumbs" :key="index">
                        <router-link :to="'/file-manager/' + breadcrumb.uuid">{{ breadcrumb.name }}</router-link>
                        <span v-if="index < breadcrumbs.length - 1"> > </span>
                      </span>

                    </p>


                    <div class="row file-manager-top-nav">
                      
                      
                      <div class="col-md-6">
                        <FileUpload @upload="uploadFile" @filesFetched="getFiles(selectedFolder)"
                          :progress="uploadProgress" />
                      </div>
                      <div class="col-md-6 text-end">
                        <button @click="createFolder" style="margin-left:2px" class="btn btn-primary btn-sm"><i class="fa fa-folder folder-icon"></i> Create New Folder</button>
                        <button @click="openSearchBox" style="margin-left:2px" class="btn btn-primary  btn-sm ml-2"><i class="fa fa-search "></i> Search</button>
                        

                      </div>

                      
                    </div>
                  </div>
                </div>

                <div class="row" v-if="!selectedFolder">
                  <div v-for="folder in folders" :key="folder.id"
                    class="col-lg-3 col-md-4 col-sm-6 mb-4 position-relative">
                    <FolderCard :folder="folder" @dblclick.native="routeToFolder(folder.uuid)">
                      <template v-slot:icon>
                        <i
                          :class="folder.expanded ? 'fa fa-folder-open text-warning' : 'fa fa-folder text-warning'"></i>
                      </template>
                    </FolderCard>
                    <i class="fa fa-trash delete-icon" @click="deleteFolder(folder.id)"></i> <!-- Add delete icon -->
                    <i class="fa fa-edit edit-icon" @click="renameFolder(folder.id)"></i> <!-- Add rename icon -->
                  </div>
                </div>
                <div class="row" v-if="selectedFolder">
                  <div class="row mt-4">
                    <div v-for="file in files.children" :key="file.id"
                      class="col-lg-3 col-md-4 col-sm-6 mb-4 position-relative">
                      <FolderCard :folder="file" @dblclick.native="routeToFolder(file.uuid)">
                        <template v-slot:icon>
                          <i
                            :class="file.expanded ? 'fa fa-folder-open text-warning' : 'fa fa-folder text-warning'"></i>
                        </template>
                      </FolderCard>
                      <i class="fa fa-trash delete-icon" @click="deleteFolder(file.id)"></i> <!-- Add delete icon -->
                      <i class="fa fa-edit edit-icon" @click="renameFolder(file.id)"></i> <!-- Add rename icon -->
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div v-for="file in files.files" :key="file.id"
                      class="col-lg-3 col-md-4 col-sm-6 mb-4 position-relative">
                      <div class="card text-center shadow-sm border-0" @click="openFile(file.url)">
                        <div class="card-body">
                          <i v-if="file.mime_type.startsWith('image/')" class="fa fa-image fa-3x text-primary mb-3"></i>
                          <i v-else-if="file.mime_type === 'application/pdf'"
                            class="fa fa-file-pdf fa-3x text-danger mb-3"></i>
                          <i v-else class="fa fa-file fa-3x text-primary mb-3"></i>
                          <h5 class="card-title">{{ file.label }}</h5>
                          <h6 class="card-subtitle text-muted">{{ file.name }}</h6>
                        </div>
                      </div>
                      <i class="fa fa-trash delete-icon" @click="deleteFile(file.id)"></i> <!-- Add delete icon -->
                    </div>
                  </div>
                </div>

              </div>


            </div>
          </div>
        </BlockUI>
      </section>
      <!-- End Services Section -->
    </main>
    <Dialog v-model:visible="showSearchDialog" header="Search files or folders" :style="{ width: '25rem' }"
      :position="'topright'">
      <form @submit.prevent="searchFiles">
        <div class="form-group">
          <input ref="searchInput" class="form-control" type="text" v-model="filters.keywords">

        </div>
      </form>

      <div class="row">
        <div class="col-md-12">
          <label for="">Files</label>

          <p v-for="searchedFile in searchResults.files" :key="searchedFile">

            <router-link :to="'/file-manager/' + searchedFile.folder_uuid">{{ searchedFile.name
              }}</router-link>

          </p>


          <p class="alert alert-success">{{ searchResults.files.length }} files found.</p>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-12">
          <label for="">Folders</label>
          <p v-for="searchedFolder in searchResults.folders" :key="searchedFolder">
            <router-link :to="'/file-manager/' + searchedFolder.uuid">{{ searchedFolder.name
              }}</router-link>

          </p>
          <p class="alert alert-success">{{ searchResults.folders.length }} folders found.</p>
        </div>
      </div>
    </Dialog>
    <!-- End #main -->
  </BlockUI>
</template>

<script>
import axios from "axios";
import moment from "moment";

import PageHeader from "../shared/PageHeader.vue";
import Checkbox from "primevue/checkbox";
import Fieldset from "primevue/fieldset";
import FolderCard from "../../components/FolderCard.vue"; // Import the new component
import FileUpload from "../../components/FileUpload.vue"; // Import the FileUpload component
import CustomTree from "../../components/CustomTree.vue"; // Import the CustomTree component
import Dialog from 'primevue/dialog';


export default {
  components: {
    PageHeader,
    Checkbox,
    Fieldset,
    FolderCard, // Register the new component
    FileUpload, // Register the FileUpload component
    CustomTree, // Register the CustomTree component,
    Dialog
  },
  data() {
    return {
      route_obj: {
        name: "create-user",
      },
      filters: {
        keywords: "",
      },
      searchResults: {
        files: [],
        folders: [],
      },
      showSearchDialog: false,
      header_button: true,
      header_button_text: "New User",
      base_url: process.env.VUE_APP_SERVICE_URL,
      users: [],
      folders: [],
      foldersTree: [],
      pagination_info: [],
      roles: [],
      selectedFolder: this.$route.params.folderId, //this is the id from the browser
      new_petition_index: {},
      showSearchForm: true,
      isLoaded: false,
      files: [], // Add this line to store files
      breadcrumbs: [], // Add this line to store files
      uploadProgress: 0, // Add this line to track upload progress
    };
  },
  created() {
    this.getUsers();
    this.getFoldersTree();
    if (this.selectedFolder) {
      this.getFiles(this.selectedFolder);
    } else {
      this.getFolders();
    }
  },
  methods: {

    openSearchBox() {
      this.showSearchDialog = true;
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },
    routeToFolder(uuid) {
      this.$router.push({ path: `/file-manager/${uuid}` });
    },
    formateDate(date) {
      if (date) {
        return moment(String(date)).format("DD/MM/YYYY"); //for  time hh:mm
      }
    },

    onPage(event) {
      this.isLoaded = false;
      var new_page_no = event.page + 1; //adding 1 because event.page returns index of page # clicked.
      this.filters.page = new_page_no;

      this.getUsers();
    },
    getUsers() {
      this.isLoaded = false;
      let url = this.base_url + "/api/users";
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("lfms_user"),
      };
      axios
        .get(url, {
          headers,
          params: this.filters,
        })
        .then((response) => {
          this.isLoaded = true;
          this.users = response.data.users.data;
          this.pagination_info = response.data.users;
          this.roles = response.data.roles;
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            title: "Something went wrong!",
            text: error.response.data.message,
          });

          console.log(error);
        });
    },

    getFolders() {
      this.isLoaded = false;
      let url = this.base_url + "/api/folders";
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("lfms_user"),
      };
      axios
        .get(url, {
          headers,
          params: this.filters,
        })
        .then((response) => {
          this.isLoaded = true;
          this.folders = response.data;

        })
        .catch((error) => {
          this.$notify({
            type: "error",
            title: "Something went wrong!",
            text: error.response.data.message,
          });

          console.log(error);
        });
    },

    getFoldersTree() {
      this.isLoaded = false;
      let url = this.base_url + "/api/folders-tree";
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("lfms_user"),
      };
      axios
        .get(url, {
          headers,
          params: this.filters,
        })
        .then((response) => {
          this.isLoaded = true;
          this.foldersTree = response.data;
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            title: "Something went wrong!",
            text: error.response.data.message,
          });
          console.log(error);
        });
    },

    getFiles(folderUUid) {
      this.isLoaded = false;
      let url = `${this.base_url}/api/folders/${folderUUid}/files`;
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("lfms_user"),
      };
      axios
        .get(url, {
          headers,
        })
        .then((response) => {
          this.isLoaded = true;
          this.files = response.data.list;
          this.breadcrumbs = response.data.breadcrumbs;
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            title: "Something went wrong!",
            text: error.response.data.message,
          });
          console.log(error);
        });
    },

    uploadFile(file) {
      const formData = new FormData();
      formData.append('file', file);

      const headers = {
        Authorization: `Bearer ` + localStorage.getItem("lfms_user"),
      };

      axios.post(`${this.base_url}/api/upload`, formData, {
        headers,
        onUploadProgress: progressEvent => {
          this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }
      })
        .then(response => {
          this.$notify({
            type: "success",
            title: "Success",
            text: "File uploaded successfully!",
          });
          this.uploadProgress = 0; // Reset progress after upload
          this.$emit('filesFetched'); // Emit filesFetched event
        })
        .catch(error => {
          this.$notify({
            type: "error",
            title: "Something went wrong!",
            text: error.response.data.message,
          });
          this.uploadProgress = 0; // Reset progress on error
        });
    },

    createFolder() {
      const folderName = prompt("Enter the name of the new folder:");
      if (!folderName) return;

      const headers = {
        Authorization: `Bearer ` + localStorage.getItem("lfms_user"),
      };

      const data = {
        name: folderName,
      };

      if (this.selectedFolder) {
        data.parent_id = this.selectedFolder;
      }

      axios.post(`${this.base_url}/api/folders`, data, { headers })
        .then(response => {
          this.$notify({
            type: "success",
            title: "Success",
            text: "Folder created successfully!",
          });
          this.getFoldersTree(); // Refresh folder tree
          if (this.selectedFolder) {
            this.getFiles(this.selectedFolder); // Refresh file list if inside a folder
          } else {
            this.getFolders(); // Refresh folder list if in root
          }
        })
        .catch(error => {
          this.$notify({
            type: "error",
            title: "Something went wrong!",
            text: error.response.data.message,
          });
        });
    },

    deleteFolder(folderId) {
      if (confirm("Are you sure you want to delete this folder?")) {
        const headers = {
          Authorization: `Bearer ` + localStorage.getItem("lfms_user"),
        };

        axios.delete(`${this.base_url}/api/folders/${folderId}`, { headers })
          .then(response => {
            this.$notify({
              type: "success",
              title: "Success",
              text: "Folder deleted successfully!",
            });
            this.getFoldersTree(); // Refresh folder tree
            if (this.selectedFolder) {
              this.getFiles(this.selectedFolder); // Refresh file list if inside a folder
            } else {
              this.getFolders(); // Refresh folder list if in root
            }
          })
          .catch(error => {
            this.$notify({
              type: "error",
              title: "Something went wrong!",
              text: error.response.data.message,
            });
          });
      }
    },

    renameFolder(folderId) {
      const newFolderName = prompt("Enter the new name for the folder:");
      if (!newFolderName) return;

      const headers = {
        Authorization: `Bearer ` + localStorage.getItem("lfms_user"),
      };

      const data = {
        name: newFolderName,
      };

      axios.put(`${this.base_url}/api/folders/${folderId}`, data, { headers })
        .then(response => {
          this.$notify({
            type: "success",
            title: "Success",
            text: "Folder renamed successfully!",
          });
          this.getFoldersTree(); // Refresh folder tree
          if (this.selectedFolder) {
            this.getFiles(this.selectedFolder); // Refresh file list if inside a folder
          } else {
            this.getFolders(); // Refresh folder list if in root
          }
        })
        .catch(error => {
          this.$notify({
            type: "error",
            title: "Something went wrong!",
            text: error.response.data.message,
          });
        });
    },

    deleteFile(fileId) {
      if (confirm("Are you sure you want to delete this file?")) {
        const headers = {
          Authorization: `Bearer ` + localStorage.getItem("lfms_user"),
        };

        axios.delete(`${this.base_url}/api/files/${fileId}`, { headers })
          .then(response => {
            this.$notify({
              type: "success",
              title: "Success",
              text: "File deleted successfully!",
            });
            this.getFiles(this.selectedFolder); // Refresh file list
          })
          .catch(error => {
            this.$notify({
              type: "error",
              title: "Something went wrong!",
              text: error.response.data.message,
            });
          });
      }
    },

    openFile(url) {
      window.open(url, '_blank');
    },

    searchFiles() {
      this.isLoaded = false;
      let url = `${this.base_url}/api/folders-search`;
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("lfms_user"),
      };
      axios
        .get(url, {
          headers,
          params: this.filters,
        })
        .then((response) => {
          this.isLoaded = true;
          this.searchResults = response.data;
          this.$nextTick(() => {
            this.$refs.searchInput.focus();
          });

        })
        .catch((error) => {
          this.$nextTick(() => {
            this.$refs.searchInput.focus();
          });
          this.isLoaded = true;
          this.$notify({
            type: "error",
            title: "Something went wrong!",
            text: error.response.data.message,
          });
          console.log(error);
        });
    },

    editUser(userToUpdate) {
      if (true) {
        var headers = {
          Authorization: `Bearer ` + localStorage.getItem("lfms_user"),
        };

        axios
          .post(this.base_url + "/api/users", userToUpdate, {
            headers,
          })
          .then(
            (response) => {
              if (response.status === 200) {
                console.log(response.data);
                this.$notify({
                  type: "success",
                  title: "Success",
                  text: "Saved Successfully!",
                });
                userToUpdate.editMode = false;
              }
            },
            (error) => {
              console.log(error.response.data);
              this.$notify({
                type: "error",
                title: "Something went wrong!",
                text: error.response.data.message,
              });
            }
          );
      }
    },
    deleteUser(event, userId, userIndex) {
      this.$confirm.require({
        target: event.currentTarget,
        message: "Do you want to Delete?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Delete",
        acceptClass: "p-button-danger",
        rejectClass: "p-button-primary",
        rejectLabel: "Cancel",
        accept: () => {
          var headers = {
            Authorization: `Bearer ` + localStorage.getItem("lfms_user"),
          };

          axios
            .delete(this.base_url + "/api/users/" + userId, {
              headers,
            })
            .then(
              (response) => {
                if (response.status === 200) {
                  this.$notify({
                    type: "success",
                    title: "Success",
                    text: "Deleted Successfully!",
                  });
                  //this.getUsers()
                  this.users.splice(userIndex, 1); //removing record from list/index after deleting record from DB
                }
              },
              (error) => {
                console.log(error.response.data);
                this.$notify({
                  type: "error",
                  title: "Something went wrong!",
                  text: error.response.data.message,
                });
              }
            );
        },
        reject: () => {
          this.$confirm.close();
        },
      });
    },
    reset() {
      this.filters = {
        role_id: "",
        is_approved: 2,
      };
      this.getUsers();
    },
  },
  mounted() {

    document.getElementById("header");
    document.title = "File Manager";
  },
};
</script>

<style scoped>
.mr {
  margin-right: 1px;
}

th {
  font-size: 13px;
}

td {
  font-size: 13px;
}

.card {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
}

.progress {
  height: 20px;
}

.progress-bar {
  background-color: #4caf50;
  text-align: center;
  color: white;
}

.delete-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: red;
}

.delete-icon:hover {
  color: darkred;
}

.edit-icon {
  position: absolute;
  top: 10px;
  right: 30px;
  cursor: pointer;
  color: blue;
}

.edit-icon:hover {
  color: darkblue;
}
.file-manager-top-nav{
  margin-bottom: 10px;
}
</style>