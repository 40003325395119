<template>
  <div
    class="col-lg-12 col-md-12 col-sm-12 mt-3 mb-5"
    style="text-align: center"
  >
    <h3>ELAWFIRM PK</h3>
    <p>
      <em>Lets start!</em><br />

      We Provide the FIR Reader. That will help you to read FIR.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
