<template>
  <div class="container" style="margin-top: 20%">
    <div class="row justify-content-center">
      <div class="col-auto">
        <h1 class="error-code"><b>404</b></h1>
      </div>
      <div class="col-sm-8 col-md-4 col-lg-4">
        <h1 class="error-text"><b>We couldn't find this page.</b></h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.title = "Page Not Found!";
  },
};
</script>

<style>
.error-code {
  font-size: 72px;
  color: #777772;
}
.error-text {
  color: #a7a7a0;
}
</style>
