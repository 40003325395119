<template>
  <div class="card text-center shadow-sm border-0" @click="routeToFolder(folder.uuid)">
    <div class="card-body">
      <i class="fa fa-folder fa-3x text-warning mb-3"></i>
      <h5 class="card-title">{{ folder.label }}</h5>
      <h6 class="card-subtitle text-muted">{{ folder.name }}</h6>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    folder: {
      type: Object,
      required: true
    }
  },
  methods: {
    routeToFolder(uuid) {
      this.$router.push({ path: `/file-manager/${uuid}` });
    }
  }
};
</script>

<style scoped>
.card {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
}
</style>
