<template>
  <ul class="tree-root">
    
    <li v-for="node in value" :key="node.id" :class="{ 'selected': node === selectedNode }">
      <div @click="toggleNode(node)" class="toggle">
        <span>
          <i :class="node.children && node.children.length ? (node.expanded ? 'fa fa-folder-open folder-icon' : 'fa fa-folder folder-icon') : 'fa fa-folder grey-folder-icon'"></i>
        </span>
        <router-link :to="`/file-manager/${node.uuid}`" class="node-label" @click.native="selectNode(node)">
          {{ node.label }}
        </router-link>
      </div>
      <ul v-if="node.expanded && node.children" class="nested">
        <CustomTree :value="node.children" />
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'CustomTree',
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedNode: null
    };
  },
  methods: {
    toggleNode(node) {
      node.expanded = !node.expanded;
    },
    selectNode(node) {
      this.selectedNode = node;
    }
  }
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

.tree-root {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  font-family: Arial, sans-serif;
}

li {
  cursor: pointer;
  padding: 5px 0;
  transition: background-color 0.3s;
}

li:hover {
  background-color: #f0f0f0;
}

.toggle {
  display: flex;
  align-items: center;
}

.node-label {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  transition: color 0.3s;
  margin-left: 5px;
}

.node-label:hover {
  color: #007bff;
}

i {
  font-size: 14px;
  transition: transform 0.3s;
}

.folder-icon {
  color: #FFD700; /* Yellow color for folder icons */
}

.grey-folder-icon {
  color: #A9A9A9; /* Grey color for folder icons without children */
}

.nested {
  padding-left: 20px;
  border-left: 1px solid #e0e0e0;
  margin-top: 4px;
}

.selected .node-label {
  color: #007bff;
  font-weight: bold;
  background-color: #e6f7ff;
  padding: 2px 5px;
  border-radius: 3px;
}
</style>
